<template>
  <div class="voteResult">
    <div class="section-warpper">
      <div class="section-title">业主大会结果公示</div>
      <div class="section-result">
        <div class="result-item">
          <span>{{ result.vaildVoteCount }}</span>
          <span>已表决有效票数</span>
        </div>
        <div class="result-item">
          <span>{{ result.vaildOwnersRatio }}%</span>
          <span>占业主总人数比</span>
        </div>
        <div class="result-item">
          <span>{{ result.vaildSizeRatio }}%</span>
          <span>建筑面积占比</span>
        </div>
      </div>

      <div class="section-title">{{ detail.title }}</div>
      <div class="section-text">
        {{ detail.votePublicityContent || detail.content }}
      </div>
      <div class="section-picture">
        <vote-pic-list
          :picList="detail.imageUrls ? detail.imageUrls.split(',') : []"
        ></vote-pic-list>
      </div>
      <div class="section-data">
        <div
          class="showItem"
          :class="r.type | bgCss"
          v-for="(r, index) in result.voteChoiceList"
          :key="index"
        >
          <div class="showItem-row">
            <span>{{ optionMap[r.type] }}</span>
            <span>{{ r.persons }}</span>
          </div>
          <template v-if="r.persons != 0">
            <div class="showItem-p">
              <span>占业主总人数比例</span>
              <span>{{ r.ownersRatio }}%</span>
            </div>
            <div class="showItem-p">
              <span>占建筑面积之和</span>
              <span>{{ r.sizeCount }}</span>
            </div>
            <div class="showItem-p">
              <span>占业主实际表决面积之和比例</span>
              <span>{{ r.sizeRatio }}%</span>
            </div>
          </template>
        </div>
      </div>
      <div class="section-tip">
        <span>
          法律依据：民法典第二百八十条业主大会或者业主委员会的决定，
          对业主具有法律约束力。
          业主大会或者业主委员会作出的决定侵害业主合法权益的，
          受侵害的业主可以请求人民法院予以撤销。
        </span>
      </div>

      <div class="surebtn" @click="viewResultNotic">
        <div class="btn">查看结果公告</div>
      </div>
    </div>
    <pdf-View
      :show="show"
      :url="detail.resultNotice"
      @check="clickCheck"
    ></pdf-View>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getVoteResultURL, getDetailURL } from "./api";
import votePicList from "./components/picList.vue";
import pdfView from "./components/pdfView.vue";

export default {
  components: {
    votePicList,
    pdfView,
  },
  data() {
    return {
      voteId: "",
      detail: {
        title: "",
        content: "",
        votePublicityContent: "",
        imageUrls: "",
      },
      result: {
        vaildVoteCount: "",
        vaildOwnersRatio: "",
        vaildSizeRatio: "",
        voteChoiceList: [
          // {
          //   type: 1,
          //   persons: "",
          //   ownersRatio: "",
          //   sizeCount: "",
          //   sizeRatio: "",
          // },
        ],
      },
      optionMap: {
        1: "支持",
        2: "反对",
        3: "弃权-随大多数票",
        4: "弃权-不随大多数票",
      },
      show: false,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  filters: {
    bgCss(val) {
      let className = {
        1: "blue",
        2: "red",
        3: "green",
        4: "green",
      };
      return className[val];
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      const { id } = this.$route.query;
      this.voteId = id;
      this.getVoteResult();
      this.getDetail();
    },
    async getVoteResult() {
      let params = {
        voteId: this.voteId,
      };
      let res = await this.$axios.get(getVoteResultURL, { params: params });
      if (res.code === 200) {
        this.result = res.data;
      }
    },
    async getDetail() {
      let params = {
        id: this.voteId,
        userId: this.userId,
      };
      let res = await this.$axios.get(getDetailURL, { params: params });
      if (res.code === 200 && res.data) {
        this.detail = res.data;
      }
    },
    viewResultNotic() {
      if (!this.detail.resultNotice) {
        this.$toast({
          message: "暂无投票结果公告",
          forbidClick: true,
          duration: 2000,
        });
        return;
      }
      this.show = true;
    },
    clickCheck() {
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.voteResult {
  box-sizing: border-box;
  width: 100%;
  background: #f6f8fb;
  font-family: PingFangSC-Regular, PingFang SC;
  padding: 20px 30px;
  padding-bottom: calc(
    40px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    40px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-warpper {
    min-height: calc(100vh - 60px - constant(safe-area-inset-bottom));
    min-height: calc(100vh - 60px - env(safe-area-inset-bottom));
    background: #ffffff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 50px 36px 44px;
    border-radius: 16px;
  }
  .section-title {
    font-size: 32px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 44px;
    margin-bottom: 30px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
  }
  .section-result {
    background: #fffbfb;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 0;
    margin-bottom: 64px;
    .result-item {
      &:not(:last-child) {
        border-right: 2px solid #e5e5e5;
      }
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      text-align: center;
      span:first-child {
        font-size: 36px;
        line-height: 50px;
        height: 50px;
        font-weight: bold;
        color: #7b4244;
        margin-bottom: 14px;
      }
      span:last-child {
        font-size: 24px;
        line-height: 34px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .section-text {
    font-size: 28px;
    font-weight: 400;
    color: #7b4244;
    line-height: 40px;
    margin-bottom: 30px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
  }
  .section-picture {
    margin-bottom: 30px;
  }
  .section-data {
    .showItem {
      font-size: 30px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 42px;
      border-radius: 8px;
      padding: 26px 28px;
      margin-bottom: 22px;
      &.blue {
        background: #ecf4ff;
        .showItem-row {
          span {
            &:last-child {
              color: #0578fa;
            }
          }
        }
      }
      &.red {
        background: #fff5f4;
        .showItem-row {
          span {
            &:last-child {
              color: #7b4244;
            }
          }
        }
      }
      &.green {
        background: #f0f9f9;
        .showItem-row {
          span {
            &:last-child {
              color: #276767;
            }
          }
        }
      }
      .showItem-row,
      .showItem-p {
        display: flex;
        align-items: center;
        span:first-child {
          flex: 1;
        }
        span:last-child {
          font-size: 26px;
        }
      }
      .showItem-p {
        margin-top: 20px;
        padding-left: 28px;
        span:first-child {
          font-size: 26px;
        }
        span:last-child {
          color: #276767;
          font-size: 26px;
        }
      }
    }
  }
  .section-tip {
    font-size: 24px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 34px;
  }
  .surebtn {
    margin-top: 78px;
    width: 100%;
    display: flex;
    justify-content: center;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 492px;
      height: 74px;
      background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
      border-radius: 39px;
      border: 8px solid #ecf7ff;
      font-weight: bold;
      font-size: 30px;
      color: #ffffff;
      letter-spacing: 4px;
    }
  }
}
</style>
